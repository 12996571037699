import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  standalone: true,
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], field: string): any[] {
    if (!Array.isArray(array)) {
      return array;
    }

    let reverse = false;
    if (field.startsWith('-')) {
      reverse = true;
      field = field.substring(1);
    }

    array.sort((a, b) => {
      if (a[field] < b[field]) {
        return reverse ? 1 : -1;
      } else if (a[field] > b[field]) {
        return reverse ? -1 : 1;
      } else {
        return 0;
      }
    });

    return array;
  }
}

export interface IMediaParameters {
  event: string;
  campaign: string;
  fbclid: string;
  gclid: string;
  medium: string;
  source: string;
  fbc?: string;
  fbp?: string;
}

export type IMediaParametersWithoutEvent = Omit<IMediaParameters, 'event'>;

export const DATALAYER_TRAFFIC_ORIGIN_KEY = 'origem-trafego';
export const MEDIA_PARAMETERS_SESSION_STORAGE_KEY = 'media_parameters_session';

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'qsc-mini-card',
  templateUrl: './mini-card.component.html',
  styleUrls: ['./mini-card.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, SvgIconComponent, TranslateModule],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniCardComponent implements OnInit {
  @Input() iconClass? = '';
  @Input() iconSvg? = '';
  @Input() title = '';
  @Input() colorful = false;
  @Input() centralizedContent = false;
  @Input() useParagraph = false;
  modifierClass = '';

  ngOnInit(): void {
    this.modifierClass = this.getModifierClass();
  }

  getModifierClass() {
    return this.colorful ? `mini-card--colorful` : '';
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PictureComponent } from '../picture/picture.component';
import { IOptimizedImageData } from '@models/index';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'qsc-descriptive-card',
  standalone: true,
  imports: [CommonModule, PictureComponent, TranslateModule],
  templateUrl: './descriptive-card.component.html',
  styleUrls: ['./descriptive-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptiveCardComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() image?: IOptimizedImageData;
}

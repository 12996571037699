<div class="maps-embed">
  <iframe
    title="maps"
    width="100%"
    height="100%"
    style="border: 0"
    loading="lazy"
    allowfullscreen
    referrerpolicy="no-referrer-when-downgrade"
    [src]="getSrc() | safe : 'resourceUrl'"
  >
  </iframe>
</div>

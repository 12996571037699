import { Injectable, Renderer2 } from '@angular/core';
import { UiService } from '@core/index';
import { BackToTopService } from '@shared/services/back-to-top/back-to-top.service';
import { BootstrapConfigService } from '@shared/services/bootstrap-config/bootstrap-config.service';
import { IframeStylerService } from '@shared/services/iframe-styler/iframe-styler.service';
import { PageScrollerService } from '@shared/services/page-scroller/page-scroller.service';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class UiAppFacade {
  constructor(
    public readonly uiService: UiService,
    public readonly windowRef: WindowRefService,
    public readonly pageScrollerService: PageScrollerService,
    public readonly backToTopService: BackToTopService,
    public readonly iframeStyler: IframeStylerService,
    public readonly renderer: Renderer2,
    public readonly bootstrapConfig: BootstrapConfigService
  ) {}
}

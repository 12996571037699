<section class="about">
  <div class="bg-color-light-3">
    <div class="about__cards-container max-w-inner-content">
      <h2
        class="about__title"
        [innerHTML]="'@HOME-ABOUT-TITLE' | translate"
      ></h2>
      <div class="row gy-3">
        <div *ngFor="let card of data.cards" class="col-12 col-md-6">
          <qsc-card-more-about [data]="card"></qsc-card-more-about>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white">
    <h2
      class="about__steps-title gradient-text max-w-inner-content"
      [innerHTML]="'@HOME-ABOUT-STEPS-TITLE' | translate"
    ></h2>
    <div class="about__content max-w-inner-content">
      <div class="about__swiper-container">
        <swiper [config]="swiperConfig">
          <ng-template swiperSlide
            ><div class="about__steps">
              <img
                [src]="
                  uiService.isDesktopXl
                    ? (data.stepsImages.desktop | fileHash)
                    : (data.stepsImages.mobile | fileHash)
                "
                [alt]="'@HOME-ABOUT-STEPS-TITLE' | translate"
              />
              <div
                *ngFor="let step of data.steps; let i = index"
                [class]="'about__step ' + 'about__step--' + (i + 1)"
              >
                <p class="about__step-title" [innerHTML]="'0' + (i + 1)"></p>
                <p
                  class="about__step-description"
                  [innerHTML]="step | translate"
                ></p>
              </div></div
          ></ng-template>
        </swiper>

        <div class="about__obs max-w-inner-content">
          <img [src]="'assets/img/svg/icon-attention-sign.svg' | fileHash" alt="" />
          <p [innerHTML]="data.obs | translate"></p>
        </div>
      </div>

      <div class="about__numbers max-w-inner-content">
        <div class="about__number" *ngFor="let number of data.numbers">
          <qsc-animated-number
            [startNumber]="'0'"
            [endNumber]="number.value | translate"
            [isPercentage]="!!number.isPercentage"
          ></qsc-animated-number>
          <div class="about__number-legend">
            <p [innerHTML]="number.legend | translate"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

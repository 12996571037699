import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fromUser } from '@core/store';
import { CategoryIdsEnum, checkCategoryIdForScheduling } from '@models/enums';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class PrivateRouteService {
  private userData: fromUser.IUser | null = null;

  constructor(
    private readonly store: Store,
    private readonly location: Location,
    private readonly router: Router
  ) {
    this.store.select(fromUser.selectUserData).subscribe((data) => {
      this.userData = data;
    });
  }

  canActivateForAddressValidation(): boolean {
    if (this.userData?.segmentId) {
      return true;
    }
    this.goSegmentSelection();
    return false;
  }

  canActivateForEquipmentSelection(): boolean {
    const numberOfUnits = Number(this.userData?.numberOfUnits);
    const profileId = this.userData?.profileId;
    const categoryId = this.userData?.categoryId;
    const isTmeo = this.userData?.tmeo;
    const isInhabited =
      categoryId === CategoryIdsEnum.Inhabited && numberOfUnits <= 9;
    const isNewHousing =
      categoryId === CategoryIdsEnum.NewHousing && !numberOfUnits;
    const shouldAllow = isInhabited || isNewHousing;

    if (
      profileId &&
      ((isTmeo && this.hasEssentialUserData()) || (!isTmeo && shouldAllow))
    ) {
      return true;
    }

    if (!profileId) {
      this.goSegmentSelection();
    } else {
      this.goBack();
    }

    return false;
  }

  canActivateForPersonalData(): boolean {
    const segmentId = this.userData?.segmentId;
    const isTmeo = this.userData?.tmeo;
    const profileId = this.userData?.profileId;
    const hasGeoData = this.userData?.latitude && this.userData?.longitude;
    const tmeoAllow = isTmeo && hasGeoData;
    const categoryAllow = !isTmeo && hasGeoData && profileId;

    if (segmentId && (tmeoAllow || categoryAllow)) {
      return true;
    }

    if (!segmentId) {
      this.goSegmentSelection();
    } else {
      this.goBack();
    }

    return false;
  }

  canActivateForProtocol(): boolean {
    const segmentId = this.userData?.segmentId;

    if (segmentId) {
      return true;
    }

    if (!segmentId) {
      this.goSegmentSelection();
    } else {
      this.goBack();
    }

    return false;
  }

  canActivateForRegionFeedback(): boolean {
    const feedbackError = this.userData?.feedbackError;
    if (feedbackError) {
      return true;
    }
    this.goBack();
    return false;
  }

  canActivateForSchedule(): boolean {
    const segmentId = this.userData?.segmentId;
    const isTmeo = this.userData?.tmeo;
    const categoryId = this.userData?.categoryId;
    const canScheduleStatus = this.userData?.scheduling?.canSchedule?.status;

    if (
      (segmentId &&
        this.hasEssentialUserData() &&
        checkCategoryIdForScheduling(categoryId) &&
        !isTmeo) ||
      canScheduleStatus
    ) {
      return true;
    }

    if (!segmentId) {
      this.goSegmentSelection();
    } else {
      this.goBack();
    }

    return false;
  }

  canActivateForScheduleFeedback(): boolean {
    const canScheduleFeedbackStatus =
      this.userData?.scheduling?.canScheduleFeedback?.status;

    if (canScheduleFeedbackStatus) {
      return true;
    }
    this.goBack();
    return false;
  }

  canActivateForCheckSchedule(): boolean {
    const segmentId = this.userData?.segmentId;
    const isTmeo = this.userData?.tmeo;
    const categoryId = this.userData?.categoryId;
    const hasInPerson = this.userData?.scheduling?.hasInPerson;
    const hasOnline = this.userData?.scheduling?.hasOnline;

    if (
      (segmentId && checkCategoryIdForScheduling(categoryId) && !isTmeo) ||
      hasInPerson ||
      hasOnline
    ) {
      return true;
    }

    if (!segmentId) {
      this.goSegmentSelection();
    } else {
      this.goBack();
    }

    return false;
  }

  canActivateForManageSchedule(): boolean {
    const canManageScheduleStatus =
      this.userData?.scheduling?.canManageSchedule?.status;

    if (canManageScheduleStatus) {
      return true;
    }
    this.goBack();
    return false;
  }

  canActivateForScheduleContactConfirm(): boolean {
    const canScheduleContactConfirmStatus =
      this.userData?.scheduling?.canScheduleContactConfirm?.status;

    if (canScheduleContactConfirmStatus) {
      return true;
    }
    this.goBack();
    return false;
  }

  canActivateForReschedule(): boolean {
    const canReschedule = this.userData?.scheduling?.canReschedule;

    if (canReschedule) {
      return true;
    }
    this.goBack();
    return false;
  }

  private goSegmentSelection(): void {
    this.router.navigate(['selecionar-segmento']);
  }

  private goBack(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/']);
    }
  }

  private hasEssentialUserData(): boolean {
    return !!(
      this.userData?.latitude &&
      this.userData?.longitude &&
      this.userData?.email &&
      this.userData?.phoneNumber
    );
  }
}

<div
  class="mini-card"
  [ngClass]="{
    'mini-card--colorful': colorful,
    'align-items-center': centralizedContent
  }"
>
  <i aria-hidden="true" *ngIf="iconClass && !iconSvg" [class]="iconClass"></i>
  <qsc-svg-icon
    *ngIf="iconSvg && !iconClass"
    [icon]="iconSvg || ''"
  ></qsc-svg-icon>
  <h3
    *ngIf="!useParagraph; else paragraph"
    class="mini-card__title"
    [class.text-center]="centralizedContent"
    [innerHTML]="title | translate"
  ></h3>
  <ng-template #paragraph>
    <p
      class="mini-card__title"
      [class.text-center]="centralizedContent"
      [innerHTML]="title | translate"
    ></p>
  </ng-template>
</div>

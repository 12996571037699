<header id="header" class="header" [ngClass]="{ 'hide-search-bar': isFaqPage }">
  <div class="position-fixed z-1000 w-100">
    <div class="header__switcher">
      <qsc-theme-switcher class="align-self-end"></qsc-theme-switcher>
    </div>
    <div class="header__container">
      <div class="header__top">
        <div class="header__mobile d-xl-none">
          <div class="d>-flex gap-3 align-items-center pt-2">
            <qsc-hamburger-menu class="d-xl-none" />
          </div>
        </div>

        <a class="header__logo-img" routerLink="/" (click)="onClickLogo()">
          <img
            [src]="'assets/img/svg/logo/logo-white.svg' | fileHash"
            [title]="'@HOMEPAGE' | translate"
            [alt]="'@HOMEPAGE' | translate"
            loading="lazy"
          />
        </a>

        <button
          class="emergency-btn d-xl-none"
          (click)="openEmergencies()"
          type="button"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Emergencia"
        >
          <img
            [src]="'assets/img/svg/icon-cg-alerta.svg' | fileHash"
            alt="Emergencia"
            loading="lazy"
          />
        </button>

        <div *ngIf="!isFaqPage" class="d-none d-xl-block w-100 search">
          <qsc-search
            [id]="'desktop-search'"
            name="search"
            [options]="searchServicesData.services"
            [placeholder]="'@HEADER-BUSCA-PLACEHOLDER' | translate"
            (selectionChangeEvent)="onSelectedService($event)"
            #searchInput
          ></qsc-search>
        </div>
      </div>

      <nav class="header__nav">
        <div *ngIf="!isFaqPage" class="d-xl-none search">
          <qsc-search
            [id]="'mobile-search'"
            name="search"
            [options]="searchServicesData.services"
            [placeholder]="'@HEADER-BUSCA-PLACEHOLDER' | translate"
            (selectionChangeEvent)="onSelectedService($event)"
            #searchInput
          ></qsc-search>
        </div>
        <ul class="d-flex align-items-center">
          <li class="segment-links">
            <ng-container
              *ngFor="let link of headerData.links; trackBy: linkTrackBy"
            >
              <a
                *ngIf="link.url.startsWith('/')"
                [routerLink]="link.url"
                class="segment-link text-nowrap"
                [innerHTML]="link.label | translate"
                (click)="onSegmentClick(link.id)"
                [ngClass]="{
                  'highlighted-link': checkCurrentPageByUrl(link.url)
                }"
              ></a>

              <a
                *ngIf="!link.url.startsWith('/')"
                [href]="link.url"
                target="_blank"
                class="segment-link text-nowrap"
                [innerHTML]="link.label | translate"
                (click)="onSegmentClick(link.id)"
                [ngClass]="{
                  'highlighted-link': checkCurrentPageByUrl(link.url)
                }"
              ></a>
            </ng-container>
          </li>
          <li class="w-40 d-none d-xl-block">
            <button
              class="emergency-btn"
              (click)="openEmergencies()"
              type="button"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Emergencia"
            >
              <img
                [src]="'assets/img/svg/icon-cg-alerta.svg' | fileHash"
                alt="Emergencia"
                loading="lazy"
              />
            </button>
          </li>
          <li class="w-60" *ngIf="isMatched('(min-width: 75em)')">
            <button class="button--green" (click)="contractNow()" type="button">
              <div class="d-flex justify-content-center">
                <span
                  class="d-xl-none"
                  [innerHTML]="
                    isSwimmingPoolPage
                      ? ('@SPEAK-TO-CONSULTANT' | translate)
                      : ('@HEADER-CONTRATAR-GAS-ENCANADO-MOBILE' | translate)
                  "
                ></span>
                <span
                  class="text-nowrap d-none d-xl-block"
                  [innerHTML]="
                    isSwimmingPoolPage
                      ? ('@SPEAK-TO-CONSULTANT' | translate)
                      : ('@HEADER-CONTRATAR-GAS-ENCANADO' | translate)
                  "
                ></span>
              </div>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>

import { Injectable } from '@angular/core';
import { InfoWindowContentService } from '../info-window-content/info-window-content.service';
import { IMapsData } from '@shared/components';

export enum MapLocalType {
  GasStation = 'Posto de Combustível',
}

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private currentInfoWindow: google.maps.InfoWindow | null = null;

  constructor(
    private readonly infoWindowContentService: InfoWindowContentService
  ) {}

  options = {
    maxZoom: 15,
    glyphColor: 'white',
    pinScale: 1.2,
    commonPinColor: '#E74646',
    highFlowPinColor: '#7FC241',
  };

  async initMap(elementId: string): Promise<google.maps.Map> {
    const { Map: GoogleMap } = (await google.maps.importLibrary(
      'maps'
    )) as google.maps.MapsLibrary;

    return new GoogleMap(document.getElementById(elementId) as HTMLElement, {
      mapId: 'map',
      scrollwheel: true,
      keyboardShortcuts: false,
      mapTypeControl: false,
      disableDoubleClickZoom: true,
      center: { lat: -23.5505199, lng: -46.6333094 },
    });
  }

  async createMarker(
    location: IMapsData,
    map?: google.maps.Map
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;

    const position = new google.maps.LatLng(
      Number(location.latitude),
      Number(location.longitude)
    );

    const pinColor = location.highFlow
      ? this.options.highFlowPinColor
      : this.options.commonPinColor;

    const pin = await this.createPinElement(pinColor);
    const label = this.createLabel(location);
    pin.element.appendChild(label);

    const marker = new AdvancedMarkerElement({
      position,
      title: location.name,
      map: map,
      content: pin.element,
    });

    marker.addListener('click', () => this.handleMarkerClick(marker, location));

    return marker;
  }

  centerMap(
    markers: google.maps.marker.AdvancedMarkerElement[],
    map?: google.maps.Map
  ): void {
    if (markers.length === 0 || !map) return;

    const bounds = new google.maps.LatLngBounds();
    markers.forEach((marker) => bounds.extend(marker.position!));

    map?.fitBounds(bounds);
    this.setMaxZoom(map);
  }

  private handleMarkerClick(
    marker: google.maps.marker.AdvancedMarkerElement,
    location: IMapsData
  ): void {
    if (this.currentInfoWindow) {
      this.currentInfoWindow.close();
    }

    this.currentInfoWindow = new google.maps.InfoWindow({
      content: this.infoWindowContentService.createContent(
        location,
        MapLocalType.GasStation
      ),
    });

    this.currentInfoWindow.open(marker.map, marker);
  }

  private async createPinElement(
    color: string
  ): Promise<google.maps.marker.PinElement> {
    const { PinElement } = (await google.maps.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;

    return new PinElement({
      background: color,
      glyphColor: this.options.glyphColor,
      borderColor: color,
      scale: this.options.pinScale,
    });
  }

  private createLabel(location?: IMapsData): HTMLElement {
    const classColor = location?.highFlow ? 'primary' : 'error';

    const label = document.createElement('div');
    label.classList.add('label');
    label.classList.add(classColor);
    label.textContent = location?.name ?? '';

    return label;
  }

  private setMaxZoom(map: google.maps.Map): void {
    const zoomMap = map.getZoom() ?? 0;
    const maxZoom = this.options.maxZoom;

    if (zoomMap > maxZoom) {
      map.setZoom(maxZoom);
    }
  }
}

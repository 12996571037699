import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TextToSpeechService {
  private synthesis: SpeechSynthesis;
  private utterance = new SpeechSynthesisUtterance();

  constructor() {
    this.synthesis = window.speechSynthesis;
    this.configureLanguage();
    this.configureVoice();
  }

  private configureLanguage = () => (this.utterance.lang = 'pt-BR');

  private configureVoice = () =>
    (this.utterance.voice =
      this.synthesis.getVoices().find((voice) => voice.lang === 'pt-BR') ||
      null);

  speak(text: string): void {
    this.utterance.text = text;
    this.synthesis.speak(this.utterance);
  }

  stop(): void {
    if (this.synthesis.speaking) {
      this.synthesis.cancel();
    }
  }
}

import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';
import { sleep } from '@shared/utils/sleep';

@Injectable({
  providedIn: 'root',
})
export class IframeStylerService {
  constructor(private readonly windowRef: WindowRefService) {}

  async applyStyle(
    selector: string,
    styleFileDir: string,
    attempts = 10
  ): Promise<void> {
    const addStyleAttempts = attempts;
    let iframeEl = this.getIframe(selector);
    while (!iframeEl && attempts > 0) {
      await sleep(1000);
      attempts--;
      iframeEl = this.getIframe(selector);
    }

    if (!iframeEl) {
      return;
    }

    const handleIframeLoad = () => {
      iframeEl?.removeEventListener('load', handleIframeLoad);
    };

    const handleLoadOnce = (): void => {
      handleIframeLoad();
      iframeEl?.removeEventListener('load', handleLoadOnce);
    };

    iframeEl.addEventListener('load', handleLoadOnce);

    await this.addStylesheet(iframeEl, styleFileDir, addStyleAttempts);
  }

  private async addStylesheet(
    iframeEl: HTMLIFrameElement,
    styleFileDir: string,
    attempts = 10
  ): Promise<void> {
    while (attempts > 0) {
      const linkEl = this.windowRef.nativeWindow.document.createElement('link');
      linkEl.rel = 'stylesheet';
      linkEl.type = 'text/css';
      linkEl.href = styleFileDir;

      const iframeDoc =
        iframeEl.contentDocument || iframeEl.contentWindow?.document;
      if (iframeDoc && iframeDoc.head) {
        iframeDoc.head.appendChild(linkEl);
        if (this.isLinkInIframe(iframeDoc, styleFileDir)) {
          return;
        }
      }

      await sleep(500);
      attempts--;
    }
  }

  private isLinkInIframe(iframeDoc: Document, styleFileDir: string): boolean {
    const linksInIframe = Array.from(
      iframeDoc.head.getElementsByTagName('link')
    );
    return linksInIframe.some((link) => link.href.includes(styleFileDir));
  }

  private getIframe(selector: string): HTMLIFrameElement | undefined {
    return this.windowRef.nativeWindow.document.querySelector(
      selector
    ) as HTMLIFrameElement;
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { ContentAnalytics, IContentData } from '@models/index';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf, NgFor, NgTemplateOutlet, NgClass } from '@angular/common';
import { ContentPageService } from '@shared/services';
import { GoogleTagManagerService } from '@core/google-tag-manager/google-tag-manager.service';
import { HelpfulAnswerComponent } from '../helpful-answer/helpful-answer.component';
import { UiService } from '@core/index';
import { PictureComponent } from '../picture/picture.component';
import { FaqService } from '@shared/services/faq/faq.service';

interface IItemByTopic {
  id: string;
  title: string;
  data: IContentData[];
}

@Component({
  selector: 'qsc-content-faq',
  templateUrl: './content-faq.component.html',
  styleUrls: ['./content-faq.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    NgTemplateOutlet,
    MatExpansionModule,
    TranslateModule,
    HelpfulAnswerComponent,
    PictureComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentFaqComponent implements OnInit, AfterViewInit, OnDestroy {
  @ContentChild(TemplateRef) template?: TemplateRef<any>;
  @Input() titleFold = '';
  @Input() subtitleFold = '';
  @Input() items: IContentData[] = [];
  @Input() withHelpfulAnswer = false;
  @Input() itemsByTopic: IItemByTopic[] = [];
  @Input() itemsWithVariant?: {
    variant: string;
    items: IContentData[];
  };
  @Input() accordionId: number | null = null;
  @Output() opened: EventEmitter<number> = new EventEmitter<number>();
  isAccordionOpened = false;
  customEventCategory = '';

  constructor(
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly contentPage: ContentPageService,
    private readonly translate: TranslateService,
    private readonly faqService: FaqService,
    public readonly uiService: UiService
  ) {}

  ngOnInit(): void {
    this.handleCustomEventCategory();
  }

  ngAfterViewInit(): void {
    this.faqService.startTrackingClickOnLinks();
  }

  ngOnDestroy(): void {
    this.faqService.stopTrackingClickOnLinks();
  }

  get contentAnalytics() {
    return ContentAnalytics;
  }

  handleCustomEventCategory(): void {
    this.customEventCategory = this.contentPage.isPage('faq')
      ? ContentAnalytics.category.faq
      : ContentAnalytics.category.cardConteudo;
  }

  onAccordionOpened(content: IContentData): void {
    this.isAccordionOpened = true;
    this.accordionId = content.id;
    this.opened.emit(content.id);
    this.sendGtmEventOnQuestion(
      content.title,
      this.contentAnalytics.label.abrir
    );

    this.faqService.updateAnswerLinkForGtmEvent(
      {
        id: content.id,
        question: content.title,
        answer: content.description ?? '',
      },
      this.customEventCategory
    );
  }

  onAccordionClosed(content: IContentData): void {
    if (this.accordionId === content.id) {
      this.isAccordionOpened = false;
      this.accordionId = null;
    }
    this.sendGtmEventOnQuestion(
      content.title,
      this.contentAnalytics.label.fechar
    );
  }

  sendGtmEventOnQuestion(question: string, label: string): void {
    this.translate.get(question).subscribe((question) => {
      if (question) {
        this.googleTagManager.sendCustomEvent(
          this.customEventCategory,
          question,
          label
        );
      }
    });
  }

  contentTrackBy(_: number, content: IContentData) {
    return content.id;
  }
}

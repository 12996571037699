<div *ngIf="data" [class]="'colored-card ' + modifierClass">
  <div>
    <div class="colored-card__img-container">
      <qsc-picture
        [png]="{
          mobile: data.image.mobile?.png,
          desktop: data.image.desktop?.png
        }"
        [webp]="{
          mobile: data.image.mobile?.webp,
          desktop: data.image.desktop?.webp
        }"
        [alt]="data.image.alt"
        [title]="data.image.title"
        [fullWidth]="true"
      ></qsc-picture>
    </div>
    <div>
      <h3 class="colored-card__title" [innerHTML]="data.title | translate"></h3>
      <div
        class="colored-card__description"
        [class.read-more-text]="showReadMore"
        [innerHTML]="data.description | translate"
      ></div>
    </div>
  </div>
  <button class="read-more-button" (click)="toggleReadMore()">
    {{ (showReadMore ? "@CONTINUE-READING" : "@READ-LESS") | translate }}
  </button>
</div>

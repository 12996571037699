import { Injectable } from '@angular/core';
import { IChatMetadata } from '@models/chat/chat-metadata';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { BehaviorSubject, Observable } from 'rxjs';

const CONTAINER_NAME = '#web-messenger-container';
const CHAT_MESSAGE = '#chat-message';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private showChatMessage = new BehaviorSubject<boolean>(true);
  private smoochModule: any;

  constructor(private readonly translateService: TranslateService) {}

  getChatMessageStatus(): Observable<boolean> {
    return this.showChatMessage.asObservable();
  }

  updateChatMessageStatus(status: boolean): void {
    this.showChatMessage.next(status);
  }

  hideFullChat(): void {
    const container = $(CONTAINER_NAME);
    const containerMessage = $(CHAT_MESSAGE);
    if (container?.length > 0) {
      container.hide();
    }
    if (containerMessage.length > 0) {
      containerMessage.hide();
    }
    this.updateChatMessageStatus(false);
  }

  hideChat(): void {
    const container = $(CONTAINER_NAME);
    const containerMessage = $(CHAT_MESSAGE);
    if (container?.length > 0) {
      container.hide();
    }
    containerMessage.hide();
    this.updateChatMessageStatus(false);
  }

  showChat(): void {
    const container = $(CONTAINER_NAME);
    const containerMessage = $(CHAT_MESSAGE);
    if (container?.length > 0) {
      container.show();
    }
    containerMessage.show();
    this.updateChatMessageStatus(true);
  }

  async openChat(): Promise<void> {
    if (!this.smoochModule) {
      this.smoochModule = (await import('smooch/lib')).default;
    }
    this.showChat();
    this.smoochModule.open();
  }

  async openChatWithHumanSupport(metadata: IChatMetadata) {
    if (!this.smoochModule) {
      this.smoochModule = (await import('smooch/lib')).default;
    }
    this.showChat();
    metadata.payload = JSON.stringify(
      this.removeBlankAttributes(metadata.payload)
    );
    this.smoochModule.createConversation({
      displayName: 'Comgás',
      description: metadata.servico,
      metadata: metadata,
      messages: [
        {
          text: this.generateInitialMessage(metadata.status),
          type: 'text',
        },
      ],
    });
    this.smoochModule.open();
  }

  private generateInitialMessage(status: number) {
    return this.translateService.instant('@CHAT-MESSAGE-' + status);
  }

  private removeBlankAttributes(object: any): any {
    const removeEmptyOrNull = (obj: any) => {
      Object.keys(obj).forEach(
        (k) =>
          (obj[k] && typeof obj[k] === 'object' && removeEmptyOrNull(obj[k])) ||
          (!obj[k] && obj[k] !== undefined && delete obj[k])
      );
      return obj;
    };
    object = removeEmptyOrNull(object);
    return object;
  }
}

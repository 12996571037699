<div class="comparative-table">
  <div class="comparative-table__container">
    <div class="comparative-table__header">
      <div class="comparative-table__column" *ngFor="let column of columns">
        <div
          class="comparative-table__column-label"
          [innerHTML]="column.label | translate"
          [class.highlighted]="column.highlighted"
        ></div>
        <div
          *ngIf="column.highlighted"
          class="comparative-table__column-box"
        ></div>
      </div>
    </div>
    <div class="comparative-table__body">
      <div class="comparative-table__rows" *ngFor="let row of rows">
        <div class="comparative-table__row">
          <div
            class="comparative-table__row-title"
            [innerHTML]="row.title | translate"
          ></div>
          <div class="comparative-table__row-container">
            <div
              class="comparative-table__comparative"
              *ngFor="let comparative of row.comparative"
            >
              <i
                aria-hidden="true"
                *ngIf="comparative.checked"
                class="icon-check_bola_preenchido"
              ></i>
              <i
                aria-hidden="true"
                *ngIf="!comparative.checked"
                class="icon-erro_fechar"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

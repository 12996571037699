<div *ngIf="image" class="first-banner">
  <div class="first-banner__background">
    <div class="first-banner__img-background">
      <qsc-picture
        [png]="{
          mobile: image.mobile?.png,
          desktop: image.desktop?.png
        }"
        [webp]="{
          mobile: image.mobile?.webp,
          desktop: image.desktop?.webp
        }"
        [breakpoints]="image.breakpoints"
        [alt]="image.alt"
        [title]="image.title"
        [fullWidth]="true"
        [fullHeight]="true"
        [lazy]="false"
      ></qsc-picture>
    </div>

    <div class="first-banner__inner">
      <ng-content></ng-content>
    </div>
  </div>
</div>

import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { SwiperOptions } from 'swiper';
import {
  BreadcrumbSwiperService,
  IBreadcrumbItem,
} from '@shared/services/breadcrumb-swiper/breadcrumb-swiper.service';
import { FlowService } from '@shared/services';
import { FlowsAnalytics } from '@models/analytics';
import { sleep } from '@shared/utils';

@Component({
  selector: 'qsc-breadcrumb-swiper',
  standalone: true,
  imports: [CommonModule, SwiperModule, TranslateModule],
  templateUrl: './breadcrumb-swiper.component.html',
  styleUrls: ['./breadcrumb-swiper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbSwiperComponent implements OnInit, AfterViewInit {
  displayedBreadcrumbs: IBreadcrumbItem[] = [];
  slideIndex = 0;
  currentBreadcrumbItem?: IBreadcrumbItem;
  homeBreadcrumbItem: IBreadcrumbItem = {
    label: 'Home',
    url: '/',
  };

  swiperConfig: SwiperOptions = {
    slidesPerView: 2.5,
    spaceBetween: 16,
    breakpoints: {
      375: {
        slidesPerView: 2.6,
      },
      390: {
        slidesPerView: 2.68,
      },
      412: {
        slidesPerView: 2.89,
      },
      420: {
        slidesPerView: 'auto',
      },
    },
  };

  @ViewChild('swiper') swiper!: SwiperComponent;

  constructor(
    private readonly breadcrumbSwiperService: BreadcrumbSwiperService,
    private readonly flowService: FlowService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.breadcrumbSwiperService.displayedBreadcrumbs$.subscribe((data) => {
      this.displayedBreadcrumbs = data;
      const { item, index } =
        this.breadcrumbSwiperService.getCurrentBreadcrumbItem();
      this.currentBreadcrumbItem = item;
      this.slideIndex = index;
      this.slideTo(this.slideIndex);
    });
  }

  ngAfterViewInit(): void {
    this.slideTo(this.slideIndex);
  }

  navigate(destination: IBreadcrumbItem) {
    this.sendGtmEvent(destination);
    this.breadcrumbSwiperService.navigateByUrl(destination.url);
  }

  private slideTo(index: number): void {
    sleep(500).then(() => {
      if (this.swiper && index > 0) {
        this.swiper.swiperRef.slideTo(index, 300);
      }
    });
  }

  private sendGtmEvent(destination: IBreadcrumbItem): void {
    if (!destination) return;
    const origin = this.currentBreadcrumbItem;

    if (origin && origin.url !== destination.url) {
      this.flowService.sendGtmEventWithFlow(
        FlowsAnalytics.category.breadcrumb,
        this.getTranslatedBreadcrumbLabel(destination),
        this.getTranslatedBreadcrumbLabel(origin)
      );
    }
  }

  private getTranslatedBreadcrumbLabel(breadcrumb: IBreadcrumbItem): string {
    return this.translateService.instant(breadcrumb.label);
  }
}

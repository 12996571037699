import { Component, Input } from '@angular/core';
import { environment } from '@env';
import { SafePipe } from '@shared/pipes';

export interface IMapsEmbedData {
  name?: string;
  address?: string;
  area?: string;
  city?: string;
}

@Component({
  selector: 'qsc-maps-embed',
  templateUrl: './maps-embed.component.html',
  styleUrls: ['./maps-embed.component.scss'],
  standalone: true,
  imports: [SafePipe],
})
export class MapsEmbedComponent {
  @Input() data: IMapsEmbedData | null = null;
  defaultMapsData = {
    latitude: -15.794229,
    longitude: -57.882166,
    zoom: 4,
  };

  getSrc(): string {
    return !this.data
      ? `https://www.google.com/maps/embed/v1/view?key=${environment.googleApiKey}&center=${this.defaultMapsData?.latitude},${this.defaultMapsData?.longitude}&zoom=${this.defaultMapsData?.zoom}&language=pt-BR`
      : `https://www.google.com/maps/embed/v1/place?key=${
          environment.googleApiKey
        }&q=${this.getLocal()}&language=pt-BR`;
  }

  private getLocal(): string {
    const local: string[] = [
      this.data?.name ?? '',
      this.data?.address ?? '',
      this.data?.area ?? '',
      this.data?.city ?? '',
    ];
    return local.join(',+');
  }
}

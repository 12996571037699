<section class="segments">
  <div class="segments__title max-w-inner-content">
    <h2 [innerHTML]="title | translate"></h2>
  </div>

  <div class="segments__cards max-w-inner-content">
    <qsc-segment-card
      *ngFor="let segment of data; trackBy: segmentTrackBy"
      [data]="segment"
    ></qsc-segment-card>
  </div>
</section>

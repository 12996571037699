import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { GoogleTagManagerService } from '@core/index';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';

@Component({
  selector: 'qsc-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
  standalone: true,
  imports: [CommonModule, FileHashPipe, TranslateModule, SvgIconComponent],
  encapsulation: ViewEncapsulation.None,
})
export class SocialShareComponent {
  @Input() showLabel = true;
  @Input() gaEventData?: any;

  constructor(private readonly googleTagManager: GoogleTagManagerService) {}

  share(platform: string) {
    this.sendGtmEvent(platform);
    const pageUrl = encodeURIComponent(window.location.href);
    const message = encodeURIComponent('Veja isso!');

    let shareUrl = '';

    switch (platform) {
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${message} ${pageUrl}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${message}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/share?url=${pageUrl}&text=${message}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, '_blank');
  }

  sendGtmEvent(platform: string): void {
    this.googleTagManager.sendCustomEvent(
      this.gaEventData.category,
      this.gaEventData.action,
      platform
    );
  }
}

import { IApiErrorContent } from '@models/error/api-error';

export interface ICriarAgendamentoInput {
  endereco: ICriarAgendamentoEnderecoInput;
  dadosPessoais: ICriarAgendamentoDadosPessoaisInput;
  dataAgendamento?: string;
  protocolo: string;
  perfil: string;
}

export interface ICriarAgendamentoEnderecoInput {
  cep: string;
  logradouro: string;
  numero: string;
  cidade: string;
  estado: string;
  bairro: string;
  complemento?: string;
  apartamento?: string;
}

export interface ICriarAgendamentoDadosPessoaisInput {
  nome: string;
  telefone: string;
}

export interface ICriarAgendamentoOutput {
  erro: IApiErrorContent | null;
  agendamento: IAgendamento | null;
  codigo: string;
  mensagem: string;
}

export interface IAgendamento {
  dataAgendamento?: Date | string;
  diaAgendamento?: string;
  horaAgendamento?: string;
}

export interface IObterAgendamentoInput extends ICriarAgendamentoEnderecoInput {
  protocolo: string;
  nome: string;
  telefone: string;
}

export interface IObterAgendamentoOutput {
  agendamento: IAgendamento;
  status?: StatusAgendamento;
}

export enum StatusAgendamento {
  AguardandoAtendimento = 0,
  EmAtendimento = 1,
  Cancelado = 2,
  Finalizado = 3,
}

export interface ICancelarAgendamentoInput {
  endereco: ICriarAgendamentoEnderecoInput;
  protocolo: string;
  perfil: string;
  nome: string;
  telefone: string;
}

export interface ICancelarAgendamentoOutput {
  erro: IApiErrorContent;
}

export interface IObterDatasDisponiveisInput {
  dataInicial: string;
  dataFinal: string;
  perfil: string;
  nome: string;
  telefone: string;
}

export type IObterDatasDisponiveisOutput = {
  datasDisponiveis: string[];
};

export interface IObterHorariosDisponiveisInput {
  data: string;
  perfil: string;
  nome: string;
  telefone: string;
}

export interface IObterHorariosDisponiveisOutput {
  horariosDisponiveis: string[];
}

export interface IVerificarCepAgendamentoInput {
  cep: string;
  perfil: string;
  nome: string;
  telefone: string;
}

export interface IVerificarCepAgendamentoOutput {
  permitido: boolean;
}

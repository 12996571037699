import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
  NgZone,
  Renderer2,
} from '@angular/core';
import { environment } from '@env';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { loadCordovaUtils, SeoJson } from '@core/index';
import {
  UiAppFacade,
  RoutingAppFacade,
  AnalyticsAppFacade,
  StorageAppFacade,
  ServicesAppFacade,
} from '@shared/services';
import { FooterAnalytics, ICriptografiaOutput } from '@models/index';
import { paserQueryString, removeBlankAttributes } from '@shared/utils';
import { IAddress } from '@modules/address-validation/models/address-validation';
import { default as chatbotJson } from '@assets/data/chatbot.json';
declare let bootstrap: any;

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewInit {
  private hostName = SeoJson.hostname;
  private smoochModule: any;
  private chatContainerId = 'web-messenger-container';
  private webMessenger = {
    selector: '#web-messenger-container',
    cssFile: {
      mobile: 'assets/web-messenger/cris-mobile.css',
      desktop: 'assets/web-messenger/cris-desktop.css',
    },
  };
  showHeader = false;

  constructor(
    private readonly uiAppFacade: UiAppFacade,
    private readonly routingAppFacade: RoutingAppFacade,
    private readonly analyticsAppFacade: AnalyticsAppFacade,
    private readonly storageAppFacade: StorageAppFacade,
    private readonly servicesAppFacade: ServicesAppFacade,
    private readonly zone: NgZone,
    private readonly renderer: Renderer2
  ) {
    this.uiAppFacade.bootstrapConfig.configure();
  }

  ngOnInit(): void {
    this.initSeoSchemas();
    this.initTranslation();
    this.initPlatformDependentServices();
    this.initRouteEvents();
    this.addMetaTags();
    this.enableBootstrapTooltips();
    this.initAddressValidationFlow();
    this.uiAppFacade.pageScrollerService.handle();
  }

  ngAfterViewInit(): void {
    if (this.servicesAppFacade.platformChecker.isBrowser()) {
      this.saveMediaParameters();
      this.subscribeToChatService();
      this.applyWebMessengerStyle();
      this.loadGoogleMapsScript();
    }
  }

  private subscribeToChatService(): void {
    this.servicesAppFacade.chatService
      .getChatMessageStatus()
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        this.adjustChatIframeHeight(status);
      });
  }

  private adjustChatIframeHeight(status: boolean): void {
    const iframe =
      this.uiAppFacade.windowRef.nativeWindow.document.getElementById(
        this.chatContainerId
      );
    if (iframe) {
      const checkIsMobile = this.uiAppFacade.uiService.isMobile
        ? '100%'
        : '672px';
      const style = status ? '200px' : checkIsMobile;
      this.uiAppFacade.renderer.setStyle(iframe, 'height', style);
    }
  }

  initSeoSchemas() {
    this.analyticsAppFacade.seoService.addSchema(SeoJson.schemas.Website);
  }

  initTranslation() {
    this.servicesAppFacade.translateService.setDefaultLang('pt-br');
    this.servicesAppFacade.translateService.use('pt-br');
  }

  private initPlatformDependentServices(): void {
    if (this.servicesAppFacade.platformChecker.isBrowser()) {
      this.loadChat();
      loadCordovaUtils();
      this.analyticsAppFacade.googleAnalyticsService.startCapturing();
    }
  }

  private initRouteEvents(): void {
    this.routingAppFacade.routerEvents
      .getEvents()
      .pipe(untilDestroyed(this))
      .subscribe(({ urlAfterRedirects }) => {
        this.analyticsAppFacade.seoService.updateMetaTags();
        this.analyticsAppFacade.seoService.updateCanonicalUrl(
          this.hostName + urlAfterRedirects
        );
        this.routingAppFacade.urlService.setCurrentUrlPath(urlAfterRedirects);
        this.servicesAppFacade.loadingService.hideLoading();
        this.handleShowHeader(urlAfterRedirects);
      });
  }

  private handleShowHeader(url: string): void {
    const routesWithoutHeader = [
      'selecionar-segmento',
      'validar-endereco',
      'selecionar-equipamentos',
      'agendamento',
      'protocolo',
      'feedback',
    ];
    const urlWithoutQueryParams = url.split('?')[0];
    const route = urlWithoutQueryParams.split('/')[1];
    this.showHeader = !routesWithoutHeader.includes(route);
  }

  private addMetaTags(): void {
    this.analyticsAppFacade.seoService.addMetaTags(SeoJson.metatags.twitter);
    this.analyticsAppFacade.seoService.addMetaTags(SeoJson.metatags.facebook);
    this.analyticsAppFacade.seoService.addMetaTags(SeoJson.metatags.googlePlus);
  }

  private enableBootstrapTooltips(): void {
    if (this.servicesAppFacade.platformChecker.isBrowser()) {
      const tooltipTriggerList =
        this.uiAppFacade.windowRef.nativeWindow.document.querySelectorAll(
          '[data-bs-toggle="tooltip"]'
        );
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      });
    }
  }

  private saveMediaParameters(): void {
    this.analyticsAppFacade.googleTagManager.saveMediaParamaters();
  }

  private applyWebMessengerStyle(): void {
    this.uiAppFacade.iframeStyler.applyStyle(
      this.webMessenger.selector,
      this.webMessenger.cssFile[
        this.uiAppFacade.uiService.isMobile ? 'mobile' : 'desktop'
      ]
    );

    this.storageAppFacade.cookiesService.applyConfig();
  }

  private async loadChat(): Promise<void> {
    const chatbotData = {
      integrationId: environment.suncoId,
      ...chatbotJson,
    };

    if (!this.smoochModule) {
      this.smoochModule = (await import('smooch')).default;
    }

    this.smoochModule.on('conversation:added', (_: any, data: any) => {
      this.addConversationMetadata(data.conversation);
    });

    this.smoochModule.on('widget:opened', this.handleWidgetOpened);
    this.smoochModule.on('widget:closed', this.handleWidgetClosed);
    this.smoochModule.on('ready', this.handleWidgetReady);

    this.smoochModule.init(chatbotData);
  }

  addConversationMetadata(data: any): void {
    if (!data || Object.keys(data.metadata ?? {})?.length > 0) return;

    const mediaParameters =
      this.analyticsAppFacade.googleTagManager.getMediaParameters();
    if (!mediaParameters) return;

    this.smoochModule.updateConversation(data.id, {
      metadata: {
        api: 'enviaLead',
        servico: 'Enviar Lead',
        payload: JSON.stringify(
          removeBlankAttributes({
            campaign: mediaParameters?.campaign,
            medium: mediaParameters?.medium,
            fbclid: mediaParameters?.fbclid,
            gclid: mediaParameters?.gclid,
            source: mediaParameters?.source,
          })
        ),
      },
    });
  }

  private pageName: string | null = null;
  private handleWidgetOpened = (): void => {
    this.zone.run(() => {
      this.servicesAppFacade.chatService.updateChatMessageStatus(false);
      this.uiAppFacade.backToTopService.updateBackToTopStatus(false);
    });

    if (!this.pageName) {
      const currentUrlPath =
        this.routingAppFacade.urlService.getCurrentUrlPath();
      this.pageName = currentUrlPath.replace('/', '');
    }

    this.analyticsAppFacade.googleTagManager.sendCustomEvent(
      FooterAnalytics.category.rodape,
      FooterAnalytics.action.chatbot,
      this.pageName
    );
  };

  private handleWidgetClosed = (): void => {
    this.zone.run(() => {
      this.servicesAppFacade.chatService.updateChatMessageStatus(true);
      this.uiAppFacade.backToTopService.updateBackToTopStatus(true);
    });
  };

  private handleWidgetReady = (): void => {
    this.zone.run(() => {
      this.servicesAppFacade.chatService.updateChatMessageStatus(true);

      let conversations = this.smoochModule.getConversations();
      if (conversations?.length > 0) return;

      this.smoochModule
        .createConversation({
          displayName: 'Comgás',
        })
        .then(() => {
          conversations = this.smoochModule.getConversations();

          for (let i = 0; i < conversations?.length; i++) {
            this.addConversationMetadata(conversations[i]);
          }
        });
    });
  };

  initAddressValidationFlow(): void {
    this.routingAppFacade.activatedRoute.queryParamMap.subscribe(
      (params: any) => {
        let addressParams = params.has('endereco')
          ? params.get('endereco')
          : null;

        if (!addressParams) {
          return;
        }

        addressParams = encodeURI(addressParams).replace(/%20/g, '+');

        this.servicesAppFacade.cryptographService
          .decrypt({ texto: addressParams })
          .subscribe((queryString: ICriptografiaOutput) => {
            if (!queryString?.texto) return;
            const address = paserQueryString<IAddress>(
              decodeURI(queryString.texto)
            );

            if (!address) return;

            this.storageAppFacade.sessionService.saveData<IAddress>(
              'address',
              address
            );
            this.routingAppFacade.router.navigate(['/validar-endereco']);
          });
      }
    );
  }

  private loadGoogleMapsScript(): void {
    const googleMapsScript = this.renderer.createElement('script');
    googleMapsScript.text = `
      (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=\`https://maps.\${c}apis.com/maps/api/js?\`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
        v: "weekly",
        key: '${environment.googleApiKey}',
        language: "pt-BR",
      });
    `;
    this.renderer.appendChild(document.body, googleMapsScript);
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgIf, NgFor, AsyncPipe, NgClass } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorStateMatcher, MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'qsc-autocomplete',
  templateUrl: './autocomplete.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgIf,
    NgClass,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatOptionModule,
    NgFor,
    MatIconModule,
    AsyncPipe,
    TranslateModule,
  ],
})
export class AutocompleteComponent implements OnInit, OnChanges {
  @Input() control = new FormControl('');
  @Input() options: string[] = [];
  @Input() outsideLabel = '';
  @Input() insideLabel = '';
  @Input() id = '';
  @Input() name = '';
  @Input() required = true;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() format = '';
  @Input() iconClass = '';
  @Input() success = true;
  @Input() searching = false;
  @Input() noData = false;
  @Input() errorMatcher: ErrorStateMatcher = new ErrorStateMatcher();
  @Input() dataTestId = '';

  @Output() selectionChangeEvent = new EventEmitter();

  @ViewChild('input')
  public input: ElementRef | undefined;

  @ViewChild(MatAutocompleteTrigger)
  autoCompleteTrigger: MatAutocompleteTrigger | undefined;

  public filteredOptions?: Observable<string[]>;

  ngOnInit() {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value ?? ''))
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      this.disabled ? this.control.disable() : this.control.enable();
    }
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  public onSelectionChange(option: any, closePanel = false): void {
    this.selectionChangeEvent.emit(option);

    if (closePanel) {
      this.autoCompleteTrigger?.closePanel();
    }
  }
}

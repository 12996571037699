import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SECTION_MAPPING } from '@models/section-mapping/section-mapping';
import { filter, interval, takeWhile } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageScrollerService {
  private readonly sectionMapping = SECTION_MAPPING;

  constructor(
    private readonly router: Router,
    private readonly viewportScroller: ViewportScroller,
    @Inject(DOCUMENT) private document: Document
  ) {}

  handle(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const state = this.router.routerState.snapshot;
        const value = state.root.queryParams['secao'];
        const elementId = this.sectionMapping[value] || value;
        if (elementId) {
          this.scrollToAnchor(elementId);
        }
      });
  }

  scrollToAnchor(elementId: string) {
    const maxAttempts = 10;
    let attempts = 0;

    interval(500)
      .pipe(takeWhile(() => attempts < maxAttempts))
      .subscribe(() => {
        const header = this.document.querySelector('.header');
        const element = this.document.getElementById(elementId);

        if (header && element) {
          const rect = element.getBoundingClientRect();
          const scrollY = this.document.defaultView?.scrollY as number;
          const headerHeight = header.clientHeight;
          const top = rect.top + scrollY - headerHeight;
          this.viewportScroller.scrollToPosition([0, top]);
          attempts = maxAttempts;
        } else {
          attempts++;
        }
      });
  }
}

<qsc-json-ld [schema]="schemas.main"></qsc-json-ld>
<qsc-banners [data]="bannerData"></qsc-banners>
<qsc-segments [data]="segmentsData"></qsc-segments>
<qsc-quick-access [quickLinks]="quickLinks"></qsc-quick-access>
<qsc-advantages [data]="advantagesData"></qsc-advantages>
<qsc-about [data]="aboutData"></qsc-about>
<qsc-short-faq [data]="{ items: faqData, schema: schemas.faq }"></qsc-short-faq>
<qsc-testimonials [data]="testimonialsData"></qsc-testimonials>
<qsc-floating-wrapper class="d-xl-none" triggerElementId="quick-access">
  <qsc-button
    [qscEventTrackerCategory]="contentAnalytics.category.ctaFlutuante"
    [qscEventTrackerAction]="contentAnalytics.action.contratarAgora"
    [qscEventTrackerLabel]="contentAnalytics.label.clique"
    routerLink="/selecionar-segmento"
    variant="green"
    [buttonText]="'@HEADER-CONTRATAR-GAS-ENCANADO-MOBILE' | translate"
  ></qsc-button>
</qsc-floating-wrapper>

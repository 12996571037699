<div *ngIf="data" class="calendar-event">
  <add-to-calendar-button
    [attr.name]="data.title | translate"
    [attr.description]="data.description | translate : { protocol: protocol }"
    [attr.startDate]="data.startDate"
    [attr.startTime]="data.startTime"
    [attr.endTime]="data.endTime"
    [attr.timeZone]="data.timeZone | translate"
    [attr.options]="data.options | translate"
    [attr.organizer]="data.organizer ?? '' | translate"
    trigger="click"
    buttonsList
    hideCheckmark
    [attr.size]="uiService.isMobile ? '1' : '3'"
    language="pt"
    class="calendar-event__button"
  ></add-to-calendar-button>
</div>

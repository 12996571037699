<div
  class="chat-message p-2"
  id="chat-message"
  *ngIf="showChatMessage$ | async"
>
  <button
    class="btn-reset chat-message__btn-close"
    (click)="hideChatMessage()"
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    title="Fechar chat"
  >
    <i aria-hidden="true" class="icon-sinal_multiplicar_x_fechar"></i>
  </button>
  <span [innerHTML]="'@CHAT-MESSAGE-DEFAULT' | translate"></span>
</div>

<div class="input autocomplete" [ngClass]="{ 'input--gap': outsideLabel }">
  <label [htmlFor]="id" class="content-semibold">{{
    required && outsideLabel
      ? (outsideLabel | translate) + " *"
      : (outsideLabel | translate)
  }}</label>
  <mat-form-field
    appearance="fill"
    [class.is-valid]="options.length || success"
    [class.is-disabled]="disabled"
  >
    <mat-label *ngIf="insideLabel">{{ insideLabel | translate }}</mat-label>
    <input
      [attr.data-testid]="dataTestId"
      [id]="id"
      [name]="name"
      type="text"
      [placeholder]="placeholder | translate"
      [attr.aria-label]="outsideLabel | translate"
      [formControl]="control"
      [required]="required"
      [disabled]="disabled"
      [matAutocomplete]="auto"
      [errorStateMatcher]="errorMatcher"
      [class.ng-pending]="searching"
      class="truncate"
      matInput
      #input
    />

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        disabled
        *ngIf="noData"
        style="white-space: initial; line-height: initial; padding: 0.2rem 1rem"
      >
        <span [innerHTML]="'@NO-ADDRESS-FOUND' | translate"></span>
      </mat-option>
      <mat-option
        style="white-space: initial; line-height: initial; padding: 0.2rem 1rem"
        *ngFor="let option of filteredOptions | async"
        [value]="option"
        (onSelectionChange)="onSelectionChange(option)"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>

    <mat-icon
      *ngIf="
        ((control.dirty || control.touched) &&
          !control.hasError('required') &&
          (options.length || success)) ||
        control.valid
      "
      matSuffix
    >
      <i aria-hidden="true" class="icon-check_bola_preenchido"></i>
    </mat-icon>
    <mat-icon
      *ngIf="(control.dirty || control.touched) && control.hasError('required')"
      matSuffix
    >
      <i aria-hidden="true" class="icon-erro_fechar"></i>
    </mat-icon>
    <mat-icon *ngIf="searching" matSuffix>
      <img alt="" class="icon-loading" src="/assets/img/loading.gif" />
    </mat-icon>
    <mat-icon *ngIf="name === 'address'" matSuffix>
      <i aria-hidden="true" *ngIf="!success" class="icon-localizacao_gps"></i>
      <i
        aria-hidden="true"
        *ngIf="success"
        class="icon-localizacao_gps is-success"
      ></i>
    </mat-icon>

    <mat-error *ngIf="control.hasError('required')">
      {{ "@ORTEC-CAMPO-REQUERIDO" | translate }}
    </mat-error>
  </mat-form-field>
</div>

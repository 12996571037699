<a
  class="campaign-banner"
  (click)="sendGtmEventToStartFlow()"
  routerLink="/selecionar-segmento"
  aria-label="Banner"
  *ngIf="banner"
>
  <qsc-first-banner [image]="banner.image">
    <div class="campaign-banner__content">
      <div class="campaign-banner__content-left">
        <h1
          class="campaign-banner__title"
          [innerHTML]="banner.texts[0] | translate"
        ></h1>
        <div>
          <p
            class="campaign-banner__text-1"
            [innerHTML]="banner.texts[1] | translate"
          ></p>
          <p
            class="campaign-banner__text-2"
            [innerHTML]="banner.texts[2] | translate"
          ></p>
        </div>

        <div class="w-100 d-none d-lg-block">
          <qsc-button
            variant="lightGreen"
            [buttonText]="banner.buttons[0] | translate"
          ></qsc-button>
        </div>
      </div>
      <div class="campaign-banner__content-right">
        <p
          class="campaign-banner__text-3"
          [innerHTML]="banner.texts[3] | translate"
        ></p>
        <img
          class="campaign-banner__logo"
          src="assets/img/svg/logo/logo-white.svg"
          [title]="'@HOMEPAGE' | translate"
          [alt]="'@HOMEPAGE' | translate"
          loading="lazy"
        />
      </div>
    </div>
    <div class="campaign-banner__button-cta">
      <qsc-button
        class="pe-auto"
        variant="green"
        [buttonText]="banner.buttons[0] | translate"
      ></qsc-button>
    </div>
  </qsc-first-banner>
</a>

export const ContentAnalytics = {
  category: {
    banner: 'CTA_banner',
    ctaConteudo: 'CTA_conteudo',
    cardConteudo: 'card_conteudo',
    hlConteudo: 'hl_conteudo',
    programas: 'card_programas',
    encontrarPosto: 'encontrar_posto',
    solicitarContato: 'solicitar_contato',
    instalarGnv: 'instalar_gnv',
    faq: 'faq',
    calculadora: 'calculadora',
    ctaFlutuante: 'cta flutuante',
    campanhaIfood: 'campanha_ifood',
    beneficosGnv: 'beneficios_gnv',
    ctaCompartilhar: 'cta_compartilhar',
  },
  action: {
    conteudo: 'conteudo',
    cidade: 'cidade',
    bairro: 'bairro',
    posto: 'posto',
    convertedoras: 'convertedoras',
    selecionarVeiculo: 'selecionar veiculo',
    selecionarCombustivel: 'selecionar combustivel',
    calcular: 'calcular',
    calcularNovamante: 'calcular_novamente',
    receberEmail: 'receber email',
    contratarAgora: 'contratar agora',
    regulamento: 'regulamento',
    veiculosLeves: 'veiculos_leves',
    veiculosPesados: 'veiculos_pesados',
    whatsapp: 'whatsapp',
    faqConsultarTodasAsPerguntas: 'consultar todas as perguntas',
    novaPesquisa: 'nova pesquisa',
    altaVazao: 'alta vazao',
  },
  label: {
    contratarAgora: 'contratar agora',
    solicitarContato: 'solicitar contato',
    encontrarPostos: 'encontrar postos',
    calcularEconomia: 'calcular economia',
    maisSolucoes: 'mais solucoes',
    clique: 'clique',
    abrir: 'abrir',
    fechar: 'fechar',
    sim: 'sim',
    nao: 'nao',
    verMais: 'ver_mais',
    solicitarOrcamento: 'solicitar_orcamento',
    verLista: 'ver lista',
    hiperlink: 'hiperlink',
  },
} as const;

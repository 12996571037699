import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from '@core/index';
import { ContentAnalytics } from '@models/analytics';
import { PictureComponent } from '../picture/picture.component';
import { IOptimizedImageData } from '@models/index';
import { removeHtmlTags } from '@shared/utils';

export interface ColoredCardInputData {
  title: string;
  description: string;
  variant: 'turquesa' | 'orange' | 'green';
  image: IOptimizedImageData;
}

@Component({
  selector: 'qsc-colored-card',
  standalone: true,
  imports: [CommonModule, PictureComponent, TranslateModule],
  templateUrl: './colored-card.component.html',
  styleUrls: ['./colored-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColoredCardComponent implements OnInit {
  @Input() data?: ColoredCardInputData;
  @Input() showReadMore = true;
  @Output() showReadMoreChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  modifierClass = '';

  constructor(
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.modifierClass = this.getModifierClass();
  }

  toggleReadMore() {
    this.showReadMore = !this.showReadMore;
    this.showReadMoreChange.emit(this.showReadMore);

    this.googleTagManager.sendCustomEvent(
      ContentAnalytics.category.cardConteudo,
      removeHtmlTags(this.translateService.instant(this.data?.title ?? '')),
      ContentAnalytics.label.clique
    );
  }

  getModifierClass() {
    return this.data?.variant ? `colored-card--${this.data?.variant}` : '';
  }
}

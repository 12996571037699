import { Injectable } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MapLocalType } from '../google-maps/google-maps.service';
import { IMapsData } from '@shared/components';

@Injectable({
  providedIn: 'root',
})
export class InfoWindowContentService {
  constructor(
    private readonly titleCasePipe: TitleCasePipe,
    private readonly translateService: TranslateService
  ) {}

  createContent(data: IMapsData, type?: MapLocalType): string {
    const address = this.createAddress(data);
    const mapLink = this.createMapLink(data, type);
    const directionLink = this.createDirectionLink(data, type);

    return `
      <div class="info-window-content">
        <div>
          <div>
            <div class="info-window-content__name">
              ${this.titleCasePipe.transform(data.name)}
            </div>
            ${address}
          </div>
          ${mapLink}
        </div>
        <div>
          ${directionLink}
        </div>
      </div>
    `;
  }

  private createAddress(data: IMapsData): string {
    return `
      <div class="info-window-content__address">
        <div>${this.titleCasePipe.transform(data.address)} -</div>
        <div>
          ${this.titleCasePipe.transform(
            data.area
          )}, ${this.titleCasePipe.transform(data.city)} - ${data.state},
        </div>
        <div>${data.postalCode}</div>
      </div>
    `;
  }

  private createMapLink(data: IMapsData, type?: MapLocalType): string {
    const url = 'https://www.google.com/maps/search/?api=1&query=';

    return `
      <div>
        <a target="_blank" href="${url}${data.address},${data.area},${
      data.city
    },${data.localName},${data.localName ? type : ''}">
          ${this.translateService.instant('@VEHICLES-CONTENT-MAPS-LABEL-1')}
        </a>
      </div>
    `;
  }

  private createDirectionLink(data: IMapsData, type?: MapLocalType): string {
    const url = 'https://maps.google.com/maps/dir//';

    return `
      <div>
        <a target="_blank" href="${url}${data.address},${data.area},${
      data.city
    },${data.localName},${type}">
          <div class="info-window-content__navigate"></div>
          <div>${this.translateService.instant(
            '@VEHICLES-CONTENT-MAPS-LABEL-2'
          )}</div>
        </a>
      </div>
    `;
  }
}

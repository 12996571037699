import { Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Observable, distinctUntilChanged, filter, fromEvent, map } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FloatingWrapperService } from '@shared/services';

@UntilDestroy()
@Component({
  selector: 'qsc-floating-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './floating-wrapper.component.html',
  styleUrls: ['./floating-wrapper.component.scss'],
})
export class FloatingWrapperComponent implements OnInit {
  @Input() triggerElementId = '';
  isFloatinWrapperVisible$: Observable<boolean>;

  constructor(
    private ngZone: NgZone,
    private floatingWrapperService: FloatingWrapperService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isFloatinWrapperVisible$ =
      this.floatingWrapperService.getFloatingWrapperStatus();
  }

  ngOnInit() {
    this.floatingWrapperService.resetFloatingWrapperStatus();
    this.subscribeToScrollEvent();
  }

  private subscribeToScrollEvent() {
    if (!this.document.defaultView) return;
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.document, 'scroll')
        .pipe(
          map(() => this.document.defaultView?.scrollY),
          distinctUntilChanged(),
          filter(() => {
            const element = this.document.getElementById(this.triggerElementId);
            return element !== null;
          }),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.ngZone.run(() => this.checkIfShouldShow());
        });
    });
  }

  private checkIfShouldShow() {
    const triggerElementPosition = this.getTriggerElementPosition();
    const headerHeight = this.getHeaderHeight();

    const menuHambuerButton = this.document.querySelector(
      '.menu-hamburger__btn'
    ) as HTMLButtonElement;
    if (menuHambuerButton.getAttribute('aria-expanded') === 'true') {
      this.floatingWrapperService.updateFloatingWrapperStatus(false);
      return;
    }
    if (triggerElementPosition < headerHeight) {
      this.floatingWrapperService.updateFloatingWrapperStatus(true);
      return;
    }
    this.floatingWrapperService.updateFloatingWrapperStatus(false);
  }

  private getTriggerElementPosition() {
    const triggerElement = document.getElementById(
      this.triggerElementId
    ) as HTMLElement;
    return triggerElement.getBoundingClientRect().top;
  }

  private getHeaderHeight() {
    const headerElement = document.getElementById('header') as HTMLElement;
    return headerElement.offsetHeight;
  }
}

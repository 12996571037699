import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UiService } from '@core/index';
import { FlowsAnalytics } from '@models/analytics';
import { FlowService } from '@shared/services';
import 'add-to-calendar-button';

export interface ICalendarEventData {
  title: string;
  description: string;
  startDate: string;
  startTime: string;
  endTime: string;
  timeZone: string;
  options: string;
  organizer?: string;
}

@Component({
  selector: 'qsc-calendar-event',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, TranslateModule],
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss'],
})
export class CalendarEventComponent implements AfterViewInit {
  @Input() data?: ICalendarEventData;
  @Input() protocol = '';

  constructor(
    protected uiService: UiService,
    private readonly flowService: FlowService
  ) {}

  ngAfterViewInit(): void {
    this.configureCalendarEventButtons();
  }

  configureCalendarEventButtons(): void {
    const classNames = [
      '.atcb-icon-google',
      '.atcb-icon-outlookcom',
      '.atcb-icon-apple',
      '.atcb-icon-ical',
      '.atcb-icon-ms365',
      '.atcb-icon-msteams',
      '.atcb-icon-yahoo',
    ];

    classNames.forEach((className) => this.addEventClick(className));
  }

  addEventClick(className: string, tries = 0) {
    const elementoComShadowRoot = document.querySelector(
      '.calendar-event__button'
    ) as HTMLElement;

    if (elementoComShadowRoot?.shadowRoot) {
      const shadowRoot = elementoComShadowRoot.shadowRoot;

      const element = shadowRoot.querySelector(className);
      if (element) {
        element.parentElement?.addEventListener('click', () => {
          this.sendGtmOnAddCalendarEvent(className);
        });
        return;
      }
    }

    const maxTries = 10;
    const retryDelay = 100;
    if (tries < maxTries) {
      setTimeout(() => {
        this.addEventClick(className, tries + 1);
      }, retryDelay);
    }
  }

  getLabelByClassName(className: string): string {
    switch (className) {
      case '.atcb-icon-google':
        return FlowsAnalytics.label.google;
      case '.atcb-icon-outlookcom':
        return FlowsAnalytics.label.outlook;
      case '.atcb-icon-apple':
        return FlowsAnalytics.label.apple;
      case '.atcb-icon-ical':
        return FlowsAnalytics.label.ical;
      case '.atcb-icon-ms365':
        return FlowsAnalytics.label.ms365;
      case '.atcb-icon-msteams':
        return FlowsAnalytics.label.msteams;
      case '.atcb-icon-yahoo':
        return FlowsAnalytics.label.yahoo;
      default:
        return '';
    }
  }

  sendGtmOnAddCalendarEvent(className: string): void {
    const label = this.getLabelByClassName(className);

    this.flowService.sendGtmEventWithFlow(
      FlowsAnalytics.category.octagora,
      FlowsAnalytics.action.adicionarCalendario,
      label
    );
  }
}

export const getUrlParams = (url: string) => new URLSearchParams(url);

export const getQueryParams = (url: string) => {
  const urlParams = getUrlParams(url);
  return Object.fromEntries(urlParams.entries());
};

export const getUrlWithoutParams = (url: string) => {
  return url.split('?')[0];
};

<div class="input" [ngClass]="{ 'input--gap': outsideLabel }">
  <label [attr.for]="id" class="content-semibold">{{
    required && outsideLabel
      ? (outsideLabel | translate) + " *"
      : (outsideLabel | translate)
  }}</label>
  <mat-form-field appearance="fill" [class.is-valid]="isValid()">
    <mat-label *ngIf="insideLabel">{{ insideLabel | translate }}</mat-label>
    <input
      matInput
      [attr.data-testid]="dataTestId"
      [id]="id"
      class="label-search-semibold"
      [type]="type"
      [pattern]="pattern"
      [formControl]="control"
      [placeholder]="placeholder | translate"
      [disabled]="disabled"
      [attr.disabled]="disabled"
      [mask]="format"
      [required]="required"
      [tabIndex]="tabIndex"
      [errorStateMatcher]="errorMatcher"
      #input
    />
    <mat-icon *ngIf="loading" matSuffix>
      <img alt="" class="icon icon-loading" src="/assets/img/loading.gif" />
    </mat-icon>
    <mat-icon
      *ngIf="prefixIcon.enable && (name === 'email' || name === 'phone')"
      matPrefix
    >
      <i
        aria-hidden="true"
        *ngIf="!prefixIcon.iconClass && name === 'email'"
        class="icon-email"
      ></i>
      <i
        aria-hidden="true"
        *ngIf="!prefixIcon.iconClass && name === 'phone'"
        class="icon-telefone_chat"
      ></i>
      <i
        aria-hidden="true"
        *ngIf="prefixIcon.iconClass"
        [class]="prefixIcon.iconClass"
      ></i>
    </mat-icon>
    <mat-icon *ngIf="control.touched && control.dirty && !loading" matSuffix>
      <i
        aria-hidden="true"
        *ngIf="isValid() && showCheckIcon"
        class="icon-check_bola_preenchido"
      ></i>
      <i
        aria-hidden="true"
        *ngIf="control.invalid"
        class="icon-erro_fechar"
      ></i>
    </mat-icon>

    <mat-error *ngIf="control.hasError('required')">
      {{ "@ORTEC-CAMPO-REQUERIDO" | translate }}
    </mat-error>
    <mat-error *ngIf="control.touched && control.hasError('maxlength')">
      {{
        "@ORTEC-CAMPO-LIMITE-CARACTERES"
          | translate : { length: this.control.errors?.['maxlength'].requiredLength }
      }}
    </mat-error>
    <mat-error
      class="d-flex align-items-center gap-2"
      *ngIf="
        control.invalid &&
        !control.hasError('required') &&
        !control.hasError('maxlength')
      "
    >
      <qsc-svg-icon
        *ngIf="invalidErrorIconSvg"
        [icon]="invalidErrorIconSvg"
        width="1rem"
        height="1rem"
      ></qsc-svg-icon>

      {{ invalidErrorMessage || "@ORTEC-CAMPO-INVALIDO" | translate }}
    </mat-error>
  </mat-form-field>
</div>

import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgFor } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UiService } from '@core/ui/ui.service';
import { ISegment } from '@modules/home/models/home';
import { SegmentCardComponent } from '../segment-card/segment-card.component';

@Component({
  selector: 'qsc-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgFor, SegmentCardComponent, TranslateModule],
})
export class SegmentsComponent {
  @Input() data: ISegment[] = [];
  @Input() title = '@HOME-SEGMENTOS-CTA-TEXTO';

  constructor(public readonly uiService: UiService) {}

  segmentTrackBy(_: number, segment: ISegment) {
    return segment.id;
  }
}

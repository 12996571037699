<div
  class="select-segment"
  [class]="isSwimmingPoolFlow ? 'select-segment--swimming-pool' : ' '"
>
  <div class="d-md-flex justify-content-md-between align-items-md-center">
    <h3 class="select-segment__title" [innerHTML]="title | translate"></h3>
    <button class="select-segment__btn-close" (click)="backToHome()">
      <i aria-hidden="true" class="icon-sinal_multiplicar_x_fechar"></i>
    </button>
  </div>
  <div class="select-segment__content">
    <button
      class="select-segment__item"
      (click)="handle(item.id)"
      *ngFor="let item of filteredItems; let i = index"
    >
      <qsc-segment-card
        [class]="isSwimmingPoolFlow ? 'segment-card--swimming-pool' : ''"
        [data]="item"
        [withCta]="false"
        [index]="i"
        [isSwimmingPoolFlow]="isSwimmingPoolFlow"
      >
      </qsc-segment-card>
    </button>
  </div>
</div>

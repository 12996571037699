import { Injectable } from '@angular/core';
import { PlatformCheckerService } from '../platform-checker/platform-checker.service';
import { setTheme } from 'ngx-bootstrap/utils';

@Injectable({
  providedIn: 'root',
})
export class BootstrapConfigService {
  constructor(private platformChecker: PlatformCheckerService) {}

  configure(): void {
    if (this.platformChecker.isBrowser()) {
      setTheme('bs5');
    }
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BlogAnalytics } from '@models/analytics/blog-analytics';
import { TranslateModule } from '@ngx-translate/core';
import { EventTrackerDirective } from '@shared/directives';

@Component({
  selector: 'qsc-know-more',
  templateUrl: './know-more.component.html',
  styleUrls: ['./know-more.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, EventTrackerDirective],
  encapsulation: ViewEncapsulation.None,
})
export class KnowMoreComponent {
  @Input() showLabel = true;
  @Input() showBuildings = true;
  @Input() showResidential = true;
  @Input() showCommerce = true;
  @Input() showIndustries = true;
  @Input() gaEventCategory?: string;

  get blogAnalytics() {
    return BlogAnalytics;
  }
}

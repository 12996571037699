import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  RouterModule,
  RouterStateSnapshot,
  Routes,
  TitleStrategy,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AddressValidationGuard,
  EquipmentSelectionGuard,
  RegionFeedbackGuard,
  SeoJson,
} from '@core/index';
import { HomeComponent } from '@modules/home/containers/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    title: SeoJson.pages.home.title,
    data: {
      meta: {
        description: SeoJson.pages.home.description,
      },
    },
  },
  {
    path: 'selecionar-segmento',
    loadComponent: () =>
      import(
        './modules/segment-selection/containers/segment-selection/segment-selection.component'
      ).then((m) => m.SegmentSelectionComponent),
    title: SeoJson.pages.segmentSelection.title,
  },
  {
    path: 'validar-endereco',
    loadChildren: () =>
      import('./modules/address-validation/address-validation.module').then(
        (m) => m.AddressValidationModule
      ),
    title: SeoJson.pages.addressValidation.title,
    canActivate: [AddressValidationGuard],
  },
  {
    path: 'agendamento',
    loadChildren: () =>
      import('./modules/scheduling/scheduling.module').then(
        (m) => m.SchedulingModule
      ),
  },
  {
    path: 'selecionar-equipamentos',
    loadChildren: () =>
      import('./modules/equipment-selection/equipment-selection.module').then(
        (m) => m.EquipmentSelectionModule
      ),
    title: SeoJson.pages.equipmentSelection.title,
    canActivate: [EquipmentSelectionGuard],
  },
  {
    path: 'protocolo',
    loadChildren: () =>
      import('./modules/protocol/protocol.module').then(
        (m) => m.ProtocolModule
      ),
  },
  {
    path: 'feedback',
    loadChildren: () =>
      import('./modules/region-feedback/region-feedback.module').then(
        (m) => m.RegionFeedbackModule
      ),
    title: SeoJson.pages.error.title,
    canActivate: [RegionFeedbackGuard],
  },
  {
    path: 'residencias',
    loadChildren: () =>
      import('./modules/residential-content/residential-content.module').then(
        (m) => m.ResidentialContentModule
      ),
    title: SeoJson.pages.residential.title,
    data: {
      meta: {
        description: SeoJson.pages.residential.description,
      },
    },
  },
  {
    path: 'predios',
    loadChildren: () =>
      import('./modules/buildings-content/buildings-content.module').then(
        (m) => m.BuildingsContentModule
      ),
    title: SeoJson.pages.buildings.title,
    data: {
      meta: {
        description: SeoJson.pages.buildings.description,
      },
    },
  },
  {
    path: 'negocios',
    loadChildren: () =>
      import('./modules/commerce-content/commerce-content.module').then(
        (m) => m.CommerceContentModule
      ),
    title: SeoJson.pages.commerce.title,
    data: {
      meta: {
        description: SeoJson.pages.commerce.description,
      },
    },
  },
  {
    path: 'industrias',
    loadChildren: () =>
      import('./modules/industries-content/industries-content.module').then(
        (m) => m.IndustriesContentModule
      ),
    title: SeoJson.pages.industries.title,
    data: {
      meta: {
        description: SeoJson.pages.industries.description,
      },
    },
  },
  {
    path: 'veiculos',
    loadChildren: () =>
      import('./modules/vehicles-content/vehicles-content.module').then(
        (m) => m.VehiclesContentModule
      ),
    title: SeoJson.pages.vehicles.title,
    data: {
      meta: {
        description: SeoJson.pages.vehicles.description,
      },
    },
  },
  {
    path: 'duvidas-frequentes',
    loadChildren: () =>
      import('./modules/faq/faq.module').then((m) => m.FaqModule),
    title: SeoJson.pages.faq.title,
    data: {
      meta: {
        description: SeoJson.pages.faq.description,
      },
    },
  },
  {
    path: 'piscina-aquecida',
    loadChildren: () =>
      import(
        './modules/swimming-pool-content/swimming-pool-content.module'
      ).then((m) => m.SwimmingPoolContentModule),
    title: SeoJson.pages.swimmingPool.title,
    data: {
      meta: {
        description: SeoJson.pages.swimmingPool.description,
      },
    },
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./modules/blog/blog.module').then((m) => m.BlogModule),
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title) {
      this.translateService.get(title).subscribe((translatedTitle) => {
        this.title.setTitle(`${SeoJson.sitename} | ${translatedTitle}`);
      });
    } else {
      this.title.setTitle(SeoJson.sitename);
    }
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: TemplatePageTitleStrategy }],
})
export class AppRoutingModule {}

<div class="card-colored-border">
  <div class="card-colored-border__container-top">
    <div class="card-colored-border__bg-gradient"></div>
    <div class="card-colored-border__bg-image">
      <qsc-picture
        *ngIf="image"
        [webp]="{
          mobile: image.mobile?.webp,
          desktop: image.desktop?.webp
        }"
        [png]="{
          mobile: image.mobile?.png,
          desktop: image.desktop?.png
        }"
        [alt]="image.alt | translate"
        [title]="image.title ?? '' | translate"
        [fullWidth]="true"
        [fullHeight]="true"
      ></qsc-picture>
    </div>
    <h3 class="card-colored-border__title" [innerHTML]="title | translate"></h3>
  </div>
</div>

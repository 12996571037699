<div class="social-share">
  <div *ngIf="showLabel">
    <p class="social-share__title">{{ "@SOCIAL-SHARE-LABEL" | translate }}</p>
  </div>
  <div class="social-share__items">
    <button (click)="share('whatsapp')">
      <img
        [src]="'assets/img/svg/icon-cg-whatsapp-wireframe.svg' | fileHash"
        alt="whatsapp"
        title="Whatsapp"
        loading="lazy"
        class="whatsapp"
      />
    </button>
    <button (click)="share('linkedin')">
      <img
        [src]="'assets/img/svg/icon-cg-linkedin-wireframe.svg' | fileHash"
        alt="linkedin"
        title="Linkedin"
        loading="lazy"
      />
    </button>
    <button (click)="share('facebook')">
      <img
        [src]="'assets/img/svg/icon-cg-facebook-wireframe.svg' | fileHash"
        alt="facebook"
        title="Facebook"
        loading="lazy"
      />
    </button>
    <button (click)="share('twitter')">
      <img
        [src]="'assets/img/svg/icon-cg-twitter-x-wireframe.svg' | fileHash"
        alt="x"
        title="X"
        loading="lazy"
      />
    </button>
  </div>
</div>

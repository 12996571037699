<div class="input textarea" [ngClass]="{ 'input--gap': outsideLabel }">
  <label [attr.for]="id" class="content-semibold">{{
    required && outsideLabel
      ? (outsideLabel | translate) + " *"
      : (outsideLabel | translate)
  }}</label>
  <mat-form-field appearance="fill">
    <mat-label *ngIf="insideLabel">{{ insideLabel | translate }}</mat-label>
    <textarea
      matInput
      #input
      [formControl]="control"
      [id]="id"
      [name]="name"
      [placeholder]="placeholder"
      [required]="required"
      [disabled]="disabled"
      [attr.maxlength]="maxLength"
      rows="3"
    ></textarea>
    <mat-hint align="end">{{ input.value.length }}/{{ maxLength }}</mat-hint>

    <mat-error *ngIf="control.hasError('required')">
      {{ "@ORTEC-CAMPO-REQUERIDO" | translate }}
    </mat-error>
    <mat-error *ngIf="control.touched && control.hasError('maxlength')">
      {{
        "@ORTEC-CAMPO-LIMITE-CARACTERES"
          | translate : { length: this.control.errors?.['maxlength'].requiredLength }
      }}
    </mat-error>
    <mat-error
      class="d-flex align-items-center gap-2"
      *ngIf="
        control.invalid &&
        !control.hasError('required') &&
        !control.hasError('maxlength')
      "
    >
      {{ "@ORTEC-CAMPO-INVALIDO" | translate }}
    </mat-error>
  </mat-form-field>
</div>

<section class="advantages max-w-inner-content">
  <div class="advantages__title-image">
    <h2
      class="advantages__title"
      [innerHTML]="data.title.mobile | translate"
    ></h2>
    <div class="advantages__image">
      <qsc-picture
        [png]="{
          mobile: data.imageUrl.mobile?.png,
          desktop: data.imageUrl.desktop?.png
        }"
        [webp]="{
          mobile: data.imageUrl.mobile?.webp,
          desktop: data.imageUrl.desktop?.webp
        }"
        [alt]="data.imageUrl.alt"
        [title]="data.imageUrl.title"
        [fullWidth]="true"
      ></qsc-picture>
    </div>
  </div>

  <mat-accordion class="advantages__items" [multi]="!uiService.isMobile">
    <mat-expansion-panel
      *ngFor="let item of data.items; let i = index"
      hideToggle
      [expanded]="
        !uiService.isMobile || (panelOpenId === i && uiService.isMobile)
      "
      (opened)="onOpened(i)"
      (closed)="onClosed(i)"
      [disabled]="!uiService.isMobile"
      class="advantages__item"
      [ngClass]="{
        'advantages__item--active': panelOpenId === i && uiService.isMobile,
        'advantages__item--desktop': !uiService.isMobile
      }"
    >
      <span
        class="advantages__item-icon"
        [ngClass]="{
          'advantages__item-icon--active': panelOpenId === i,
          'd-none': uiService.isMobile
        }"
      >
        <i aria-hidden="true" [class]="item.icon"></i>
      </span>
      <mat-expansion-panel-header class="advantages__item-header">
        <mat-panel-title class="advantages__item-title">
          <div class="advantages__item-title-container">
            <span
              class="advantages__item-icon"
              [ngClass]="{
                'advantages__item-icon--active': panelOpenId === i,
                'd-none': !uiService.isMobile
              }"
            >
              <i aria-hidden="true" [class]="item.icon"></i>
            </span>
            <h3
              class="advantages__item-title-text mb-0"
              [innerHTML]="item.title | translate"
            ></h3>
          </div>
          <span
            class="advantages__item-toggle-icon"
            [ngClass]="{
              'advantages__item-toggle-icon--rotate': panelOpenId === i,
              'd-none': !uiService.isMobile
            }"
          >
            <i aria-hidden="true" class="icon-sinal_somar"></i
          ></span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        class="advantages__item-description"
        [innerHTML]="item.description | translate"
      ></div>
    </mat-expansion-panel>
  </mat-accordion>
</section>

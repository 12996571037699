import {
  LOCALE_ID,
  NgModule,
  Renderer2,
  TransferState,
  isDevMode,
} from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import {
  BrowserModule,
  Meta,
  ɵDomRendererFactory2,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  BackToTopComponent,
  ChatMessageComponent,
  FooterComponent,
  HeaderComponent,
} from '@shared/components';
import {
  StoreModule,
  ActionReducerMap,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { IAppState, fromUser } from '@core/store';
import { i18nPaths } from '@assets/i18n-paths';
import {
  AnalyticsAppFacade,
  RoutingAppFacade,
  ServicesAppFacade,
  StorageAppFacade,
  UiAppFacade,
} from '@shared/services';
import {
  MultiTranslateBrowserLoader,
  translateMultiBrowserLoaderFactory,
} from '@core/translation/multi-translate-browser.loader';

export function appLoaderFactory(
  http: HttpClient,
  transferState: TransferState
): MultiTranslateBrowserLoader {
  return translateMultiBrowserLoaderFactory('common', http, transferState, [
    ...i18nPaths.commonPaths,
  ]);
}

const reducers: ActionReducerMap<IAppState> = {
  user: fromUser.userReducer,
};

const metaReducers: Array<MetaReducer<IAppState>> = [];

export function localStorageSyncReducer(
  reducer: ActionReducer<IAppState>
): ActionReducer<IAppState> {
  return localStorageSync({
    keys: ['user'],
    rehydrate: true,
    storage: sessionStorage,
  })(reducer);
}

if (typeof window !== 'undefined') {
  metaReducers.push(localStorageSyncReducer);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'pt-br',
      loader: {
        provide: TranslateLoader,
        useFactory: appLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    HeaderComponent,
    FooterComponent,
    ChatMessageComponent,
    BackToTopComponent,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],
  providers: [
    Meta,
    UiAppFacade,
    RoutingAppFacade,
    AnalyticsAppFacade,
    StorageAppFacade,
    ServicesAppFacade,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: Renderer2,
      useFactory: (domRendererFactory: ɵDomRendererFactory2) =>
        domRendererFactory.createRenderer(null, null),
      deps: [ɵDomRendererFactory2],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

<div class="content-faq h-100">
  <h2
    *ngIf="titleFold"
    class="heading-bold text-light mb-0"
    [innerHTML]="titleFold | translate"
  ></h2>
  <h3
    *ngIf="subtitleFold && !itemsWithVariant"
    class="text-h4-mobile-semibold text-light mb-0"
    [innerHTML]="subtitleFold | translate"
  ></h3>
  <mat-accordion
    *ngIf="items.length && !itemsByTopic.length && !itemsWithVariant"
    class="content-faq__mat-accordion"
  >
    <mat-expansion-panel
      *ngFor="let content of items; trackBy: contentTrackBy"
      [expanded]="accordionId === content.id"
      (opened)="onAccordionOpened(content)"
      (closed)="onAccordionClosed(content)"
      [hideToggle]="true"
    >
      <mat-expansion-panel-header collapsedHeight="100%" expandedHeight="100%">
        <mat-panel-title>
          <h3
            *ngIf="!subtitleFold"
            class="text-h4-mobile-bold text-color-secondary mb-0"
            [innerHTML]="content.title | translate"
          ></h3>
          <h4
            *ngIf="subtitleFold"
            class="text-h4-mobile-bold text-color-secondary mb-0"
            [innerHTML]="content.title | translate"
          ></h4>
          <i
            aria-hidden="true"
            [class]="
              accordionId === content.id && isAccordionOpened
                ? 'icon-sinal_menos'
                : 'icon-sinal_somar'
            "
          ></i
        ></mat-panel-title>
      </mat-expansion-panel-header>
      <div [innerHTML]="content?.description ?? '' | translate"></div>
      <qsc-helpful-answer
        [question]="content.title | translate"
      ></qsc-helpful-answer>
    </mat-expansion-panel>
  </mat-accordion>
  <ng-container
    *ngIf="itemsByTopic.length && !items.length && !itemsWithVariant"
  >
    <mat-accordion
      [id]="item.id"
      class="content-faq__mat-accordion"
      *ngFor="let item of itemsByTopic; let i = index"
    >
      <h2
        class="mb-0 text-color-secondary"
        [innerHTML]="item.title | translate"
      ></h2>
      <mat-expansion-panel
        *ngFor="let content of item.data; trackBy: contentTrackBy"
        [expanded]="accordionId === content.id"
        (opened)="onAccordionOpened(content)"
        (closed)="onAccordionClosed(content)"
        [hideToggle]="true"
      >
        <mat-expansion-panel-header
          collapsedHeight="100%"
          expandedHeight="100%"
        >
          <mat-panel-title>
            <h3
              class="text-body-mobile-bold text-color-dark-2 mb-0"
              [innerHTML]="content.title | translate"
            ></h3>
            <i
              aria-hidden="true"
              [class]="
                accordionId === content.id && isAccordionOpened
                  ? 'icon-sinal_menos'
                  : 'icon-sinal_somar'
              "
            ></i
          ></mat-panel-title>
        </mat-expansion-panel-header>
        <div
          class="content-faq__description"
          [innerHTML]="content?.description ?? '' | translate"
        ></div>
        <div
          class="content-faq__template"
          *ngIf="content.withTemplate && template"
        >
          <ng-container *ngTemplateOutlet="template"></ng-container>
        </div>
        <qsc-helpful-answer
          [question]="content.title | translate"
        ></qsc-helpful-answer>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
  <ng-container
    *ngIf="itemsWithVariant && !items.length && !itemsByTopic.length"
  >
    <mat-accordion class="content-faq__mat-accordion">
      <mat-expansion-panel
        *ngFor="let content of itemsWithVariant.items; trackBy: contentTrackBy"
        class="reset-mat-expansion-panel-body-padding"
        [ngClass]="{
          'mat-expansion-panel--blue': itemsWithVariant.variant === 'blue',
          'mat-expansion-panel--green': itemsWithVariant.variant === 'green',
          'mat-expansion-panel--selected': accordionId === content.id
        }"
        [expanded]="accordionId === content.id"
        (opened)="onAccordionOpened(content)"
        (closed)="onAccordionClosed(content)"
        [disabled]="uiService.isDesktopXl && accordionId === content.id"
        [hideToggle]="true"
      >
        <mat-expansion-panel-header
          [ngClass]="{
            'p-xl-3': accordionId !== content.id,
            'px-xl-3 py-xl-4': accordionId === content.id
          }"
          [collapsedHeight]="uiService.isDesktopXl ? 'auto' : '100%'"
          [expandedHeight]="uiService.isDesktopXl ? 'auto' : '100%'"
        >
          <mat-panel-title>
            <h3 class="mb-0" [innerHTML]="content.title | translate"></h3>
            <i
              aria-hidden="true"
              class="d-xl-none"
              [ngClass]="{
                'icon-sinal_menos':
                  accordionId === content.id && isAccordionOpened,
                'icon-sinal_somar':
                  accordionId !== content.id || !isAccordionOpened
              }"
            ></i>
            <i
              aria-hidden="true"
              class="d-none d-xl-block"
              [ngClass]="{
                'icon-seta_direita':
                  accordionId === content.id && isAccordionOpened,
              }"
            ></i
          ></mat-panel-title>
        </mat-expansion-panel-header>
        <div class="img-container" *ngIf="content.image">
          <qsc-picture
            [png]="{
              mobile: content.image.mobile?.png,
              desktop: content.image.desktop?.png
            }"
            [webp]="{
              mobile: content.image.mobile?.webp,
              desktop: content.image.desktop?.webp
            }"
            [alt]="content.image.alt"
            [fullWidth]="true"
            [fullHeight]="true"
          ></qsc-picture>
        </div>
        <div
          *ngIf="!uiService.isDesktopXl && content.description"
          [innerHTML]="content.description | translate"
        ></div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</div>
